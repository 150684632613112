@import 'general.css';

/* CSS variables meant to be used in only styled components */

:root {
  /* Text-colors */
  --main-text-color: #262626;
  --main-text-color-grey: #808080;

  --blue-main: #1070b5;
  --blue-main-hovered: #0a5d98;
  --blue-main-pressed: #1262b3;
  --blue-main-disabled: #77a8d9;
  --blue-secondary: #e4f0f8;

  --element-hover: #208ad9;
  --element-blocked: #83adcb;
  --element-pressed: #024f87;
  --element-focus: #21aaf8;
  --text-white: #ffffff;
  --light-gray: #d1d1d1;

  --background-main: #f5fafe;
  --sideBar-background: #0a2b45;
  --active-in-folder: #3b5f7b;
  --menu-not-active: #d8e5ee;
  --border-blue: #005da133;

  /* Border radius */
  --border-radius: 10px;

  /* Colors */
  --blue: #0d75de;
  --light-blue: #4b9eda;
  --red: #c74045;
  --red-light: #e08789;
  --red-dark: #b90e14;
  --dark: #2c3136;
  --white: #ffffff;
  --light-placeholder: #85888c;
  --header-dark: #3d4954;
  --grey: #e7eaee;
  --panel-dark-pressed: #3b4a59;
  --light-grey: #f2f2f2;
  --tab-hovered: #dceaf5;
  --green: #249508;
  --green-hovered: #28a609;
  --grey-disabled: #f5f6f6;
  --header-dark-disabled: #c7d1db;
  --dirty-blue: #376da3;
  --light-blue-main: #2991dc;
  --light-blue-main-hovered: #53aae7;
  --dark-grey: #70808a;

  --main-box-shadow: #0000000a;

  /* Headings */
  --widget-title: #495d70;

  /* Radius */
  --default-border-radius: 10px;
}

/* Animations */

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  33% {
    transform: rotate(5deg);
  }

  66% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes modal-box {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loader {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
